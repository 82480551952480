<template>
	<div class="about">

	</div>
</template>
<script>
	import webs from "tools/webSocket.js"
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		data() {
			return {
				value: ''
			}
		},
		created() {
			this.createWs();
		},
		mounted() {
			
			
		},

		methods: {
			createWs(){
				var socket = io.connect('http://47.110.248.80:7015/det');
				socket.disconnect()
				
				socket.on('connect', function() {
				    socket.emit('data', {data: 'I\'m connected!'}, namespace='/det');
				});
				socket.on('disconnect', function() {
				    socket.emit('data', {data: 'I\'m connected!'}, namespace='/det');
				});
				
				socket.on('base', function(data) {
				    console.log(data)
				})
			},
			
		},
		destroyed() {
			
		},
	}
</script>
